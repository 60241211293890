import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

import Card from '../Sidebar/Card';

const Probe = ({ item, point, width, height }) => (
  <Box
    pos="absolute"
    left={point[0] > width / 2 ? point[0] - 330 : point[0] + 10}
    top={point[1] < height / 2 ? point[1] + 10 : point[1] - 110}
    w="320px"
  >
    <Card item={item} />
  </Box>
);

Probe.propTypes = {
  item: PropTypes.shape().isRequired,
  point: PropTypes.arrayOf(PropTypes.number).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Probe;
