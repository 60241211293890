/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

import Card from './Card';

const List = ({ nodes, handler }) => (
  <Box backgroundColor="#eee">
    {nodes.map((node, i) => (
      <Card key={node.data.Location_Name} item={node} handler={handler} index={i} />
    ))}
  </Box>
);

List.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handler: PropTypes.func.isRequired,
};

export default List;
