/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box, Heading, Text } from '@chakra-ui/react';

const Card = ({ item, handler, index }) => {
  const {
    data: { photo, Location_Name, Address },
  } = item;
  let image;
  if (photo) {
    image = getImage(photo.thumb[0]);
  }
  return (
    <Box
      key={Location_Name}
      h="100px"
      p="20px"
      mb="10px"
      backgroundColor="white"
      boxShadow="0 2px 4px rgba(0,0,0,0.25)"
      cursor="pointer"
      onClick={() => {
        if (handler && index) handler(index);
      }}
    >
      {image && (
        <Box w="60px" h="60px" mr="10px" float="left">
          <GatsbyImage image={image} alt={Location_Name} style={{ borderRadius: 3 }} />
        </Box>
      )}
      <Box w="100%">
        <Heading size="sm" fontFamily="Source Sans Pro">
          {Location_Name}
        </Heading>
        <Text>{Address}</Text>
      </Box>
    </Box>
  );
};

Card.propTypes = {
  item: PropTypes.shape().isRequired,
  handler: PropTypes.func,
  index: PropTypes.number,
};

Card.defaultProps = {
  handler: null,
  index: null,
};

export default Card;
