import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import ReactMapGL, {
  Source,
  Layer,
  NavigationControl,
  GeolocateControl,
  FlyToInterpolator,
} from 'react-map-gl';
import { Button } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/pro-regular-svg-icons';
import { faMap as solidMap } from '@fortawesome/pro-solid-svg-icons';

import Probe from './Probe';

const maxBounds = [
  [-81.32444, 29.879],
  [-81.3, 29.9055],
];

const basemapBearing = -102.4;

const mapButtonStyle = {
  pos: 'absolute',
  left: '10px',
  w: '30px',
  h: '30px',
  minW: 'none',
  backgroundColor: 'white',
  boxShadow: '0 0 2px rgba(0,0,0,0.25)',
};

const Atlas = ({ nodes, selected, handler }) => {
  const map = useRef();
  const [viewport, setViewport] = useState({
    longitude: -81.312832,
    latitude: 29.892295,
    zoom: 16,
    bearing: basemapBearing,
  });
  const [highlight, setHighlight] = useState(null);
  const [probeData, setProbeData] = useState(null);
  const [showHistoric, setShowHistoric] = useState(true);

  const clearProbe = () => {
    if (highlight) {
      map.current.getMap().setFeatureState({ source: 'points', id: highlight }, { hover: false });
      setHighlight(null);
      setProbeData(null);
    }
  };

  useEffect(() => {
    clearProbe();
    if (selected) {
      const {
        data: { longitude, latitude },
      } = selected;
      const newViewport = {
        ...viewport,
        longitude,
        latitude,
        zoom: 17,
      };
      newViewport.transitionInterpolator = new FlyToInterpolator();
      newViewport.transitionDuration = 'auto';
      setViewport(newViewport);
    }
  }, [selected]);

  const onViewportChange = newViewport =>
    setViewport({
      ...newViewport,
      zoom: Math.min(19, Math.max(13, newViewport.zoom)),
      longitude: Math.min(maxBounds[1][0], Math.max(maxBounds[0][0], newViewport.longitude)),
      latitude: Math.min(maxBounds[1][1], Math.max(maxBounds[0][1], newViewport.latitude)),
    });

  const handleClick = ({ features }) => {
    if (features.length) {
      const [feature] = features;
      handler(feature.properties.index);
    }
  };

  const handleHover = ({ features, point }) => {
    clearProbe();
    if (features.length) {
      const [feature] = features;
      map.current.getMap().setFeatureState({ source: 'points', id: feature.id }, { hover: true });
      setHighlight(feature.id);
      setProbeData({
        key: feature.id,
        item: nodes[feature.id],
        point,
        ...viewport,
      });
    }
  };

  const geojson = {
    type: 'FeatureCollection',
    features: nodes.map((item, index) => ({
      type: 'Feature',
      id: index,
      properties: {
        name: item.data.Location_Name,
        index,
        selected: isEqual(item, selected),
      },
      geometry: {
        type: 'Point',
        coordinates: [item.data.longitude, item.data.latitude],
      },
    })),
  };

  return (
    <>
      <ReactMapGL
        {...viewport}
        ref={map}
        mapboxApiAccessToken="pk.eyJ1IjoiYXhpc21hcHMiLCJhIjoieUlmVFRmRSJ9.CpIxovz1TUWe_ecNLFuHNg"
        mapStyle="mapbox://styles/axismaps/ck41kbv1f1bdy1cnwi8pdtklm"
        width="100%"
        height="100%"
        onViewportChange={onViewportChange}
        interactiveLayerIds={['point']}
        onClick={handleClick}
        onHover={handleHover}
      >
        <Source id="historicTiles" type="raster" tiles={['tiles/{z}/{x}/{y}.png']} scheme="tms">
          <Layer id="historic" type="raster" paint={{ 'raster-opacity': showHistoric ? 0.8 : 0 }} />
        </Source>
        <Source id="points" type="geojson" data={geojson}>
          <Layer
            id="point"
            type="circle"
            paint={{
              'circle-radius': 12,
              'circle-color': '#FFFFFF',
              'circle-stroke-color': [
                'case',
                ['any', ['boolean', ['feature-state', 'hover'], false], ['get', 'selected']],
                '#000000',
                '#CCCCCC',
              ],
              'circle-stroke-width': 3,
            }}
          />
          <Layer
            id="point-icon"
            type="symbol"
            layout={{ 'icon-image': 'landmark', 'icon-size': 0.75, 'icon-allow-overlap': true }}
          />
        </Source>
        <NavigationControl showCompass={false} style={{ top: 10, left: 10 }} />
        <GeolocateControl
          style={{ left: 10, top: 80 }}
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation
        />
        <Button {...mapButtonStyle} top="120px" onClick={() => setShowHistoric(!showHistoric)}>
          <FontAwesomeIcon icon={showHistoric ? faMap : solidMap} size="sm" />
        </Button>
        {probeData && <Probe {...probeData} />}
      </ReactMapGL>
    </>
  );
};

Atlas.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selected: PropTypes.shape(),
  handler: PropTypes.func.isRequired,
};

Atlas.defaultProps = {
  selected: null,
};

export default Atlas;
