/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box, Flex, Spacer, Heading, Text, Button, Link } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faStepBackward,
  faStepForward,
  faMapMarkerAlt,
} from '@fortawesome/pro-regular-svg-icons';

const Details = ({ nodes, selected, handler }) => {
  const {
    data: { photo, Location_Name, description, Address, Google_Maps_Link },
  } = selected;
  let image;
  if (photo) {
    image = getImage(photo.details[0]);
  }
  const getCurrentIndex = () => nodes.findIndex(n => isEqual(n, selected));
  return (
    <Box p="20px" key={Location_Name}>
      <Flex mb="2rem">
        <Text
          onClick={() => handler(null)}
          lineHeight="30px"
          cursor="pointer"
          _hover={{ textDecoration: 'underline' }}
        >
          <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: 10 }} />
          Back
        </Text>
        <Spacer />
        <Button onClick={() => handler(getCurrentIndex() - 1)} h={30} mr={2}>
          <FontAwesomeIcon icon={faStepBackward} />
          <Text ml={1} fontWeight="normal">
            Prev
          </Text>
        </Button>
        <Button onClick={() => handler(getCurrentIndex() + 1)} h={30}>
          <Text mr={1} fontWeight="normal">
            Next
          </Text>
          <FontAwesomeIcon icon={faStepForward} />
        </Button>
      </Flex>
      <Heading fontFamily="Raleway" fontSize={24} mb={5}>
        {Location_Name}
      </Heading>
      <Text fontSize="16px" mb={5}>
        {Address}
      </Text>
      {image && (
        <GatsbyImage
          image={image}
          loading="eager"
          alt={Location_Name}
          style={{ borderRadius: 8 }}
        />
      )}
      <Link
        color="#122E88"
        fontSize={18}
        my={2}
        display="block"
        href={Google_Maps_Link}
        target="_blank"
      >
        <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 5 }} />
        Directions
      </Link>
      <Text>{description}</Text>
    </Box>
  );
};

Details.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selected: PropTypes.shape().isRequired,
  handler: PropTypes.func.isRequired,
};

export default Details;
