import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  Grid,
  Flex,
  Spacer,
  Heading,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

import Sidebar from '../components/Sidebar';
import Atlas from '../components/Atlas';

const IndexPage = ({
  data: {
    allAirtable: { nodes },
  },
}) => {
  const [selected, setSelected] = useState(null);
  const handler = i => {
    let index = i;
    if (index < 0) index = nodes.length - 1;
    if (index >= nodes.length) index = 0;
    setSelected(nodes[index]);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        h="60px"
        alignItems="center"
        px={['10px', '30px']}
        boxShadow="0 2px 6px rgba(0,0,0,0.25)"
        zIndex={2}
        pos="relative"
        backgroundColor="white"
      >
        <FontAwesomeIcon
          icon={faUniversity}
          style={{ marginRight: 5, height: 30, width: 30, color: '#122E88' }}
        />
        <Heading fontFamily="Raleway" fontSize={20} color="#122E88">
          Interactive Map of Historic St. Augustine
        </Heading>
        <Spacer />
        <Button fontWeight="normal" background="none" px={[0, 'inherit']} onClick={onOpen}>
          <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 5 }} />
          <Text display={['none', 'block']}>About this map</Text>
        </Button>
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay />
          <ModalContent h="90vh">
            <ModalHeader>About This Map</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <iframe
                title="About this map"
                src="https://govhouse.uflib.ufl.edu/historic-st-augustine-map/"
                width="100%"
                height="100%"
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
      <Grid templateColumns={['0 100vw', '360px 1fr']}>
        <Sidebar nodes={nodes} selected={selected} handler={handler} />
        <Atlas nodes={nodes} selected={selected} handler={handler} />
      </Grid>
    </>
  );
};

export const query = graphql`
  {
    allAirtable {
      nodes {
        data {
          longitude
          latitude
          description
          Location_Name
          Google_Maps_Link
          Address
          photo {
            thumb: localFiles {
              childImageSharp {
                gatsbyImageData(width: 60, height: 60, placeholder: BLURRED)
              }
            }
            details: localFiles {
              childImageSharp {
                gatsbyImageData(width: 350, placeholder: BLURRED)
              }
            }
            # full: localFiles {
            #   childImageSharp {
            #     gatsbyImageData(width: 1024, placeholder: BLURRED)
            #   }
            # }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.shape({
    allAirtable: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
};

export default IndexPage;
