import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

import List from './List';
import Details from './Details';

const Sidebar = ({ nodes, selected, handler }) => (
  <Box
    h="calc(100vh - 60px)"
    w="100%"
    overflow="auto"
    fontFamily="Source Sans Pro"
    boxShadow="2px 0 4px rgba(0,0,0,0.25)"
    zIndex={1}
    pos={[selected ? 'absolute' : 'inherit', 'inherit']}
  >
    {selected ? (
      <Details nodes={nodes} selected={selected} handler={handler} />
    ) : (
      <List nodes={nodes} handler={handler} />
    )}
  </Box>
);

Sidebar.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selected: PropTypes.shape(),
  handler: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  selected: null,
};

export default Sidebar;
